/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
@media (max-width: 1024px) {
  #head {
    width: 87.5%;
    margin-left: 6.25%;
    margin-right: 6.25%;
    margin-top: 44px;
  }
  .cb-layout2 #head {
    width: 91.66666667%;
    margin-left: 4.16666667%;
    margin-right: 4.16666667%;
  }
}
.naviwidth {
  position: relative;
}
.sidecontent {
  width: 33.33333333%;
}
.cb-layout3 .sidecontent {
  width: 46.74479167%;
}
.maincontent {
  float: right;
  width: 66.66666667%;
}
.cb-layout3 .maincontent {
  width: 50%;
}
@media (max-width: 1024px) {
  .section--footer .desk,
  .section--social .desk {
    max-width: 91.66666667%;
  }
  .vcard {
    width: auto;
  }
  .openings {
    width: 36.36363636%;
  }
}
@media (max-width: 1024px) {
  h2 {
    font-size: 24px;
    line-height: 1.25;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 6.25%;
  margin-left: 6.25%;
}
.area .part {
  margin-right: 6.25%;
  margin-left: 6.25%;
  width: 87.5%;
}
.area .tiny {
  width: 37.5%;
}
.area > .slim .part {
  width: 87.5%;
}
.area > .slim .tiny {
  width: 37.5%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main .foot {
  margin-right: 4.16666667%;
  margin-left: 4.16666667%;
}
.cb-layout2 .main .part {
  margin-right: 4.16666667%;
  margin-left: 4.16666667%;
  width: 91.66666667%;
}
.cb-layout2 .main > .slim .part {
  width: 91.66666667%;
}
.cb-layout3 .side {
  width: 100%;
}
.cb-layout3 .side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .side .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout3 .side .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .side > .slim {
  width: 33.33333333%;
}
.cb-layout3 .side > .slim .foot,
.cb-layout3 .side > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout3 .side > .slim .part {
  width: 100%;
}
.cb-layout3 .side > .slim .tiny {
  width: 100%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base .foot {
  margin-right: 4.16666667%;
  margin-left: 4.16666667%;
}
.base .part {
  margin-right: 4.16666667%;
  margin-left: 4.16666667%;
  width: 91.66666667%;
}
.base > .slim {
  width: 50%;
}
.base > .slim .foot,
.base > .slim .part {
  margin-right: 8.33333333%;
  margin-left: 8.33333333%;
}
.base > .slim .part {
  width: 83.33333333%;
}
.base > .slim .tiny {
  width: 83.33333333%;
}
.farwest {
  width: 100%;
}
.farwest > .unit {
  margin-right: 1.30208333%;
  margin-left: 1.30208333%;
  width: 97.39583333%;
}
.farwest .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.farwest .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.farwest .tiny {
  width: 50%;
}
.farwest > .slim {
  width: 47.39583333%;
}
.farwest > .slim .foot,
.farwest > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
}
.farwest > .slim .part {
  width: 100%;
}
.farwest > .slim .tiny {
  width: 50%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south .foot {
  margin-right: 4.16666667%;
  margin-left: 4.16666667%;
}
.south .part {
  margin-right: 4.16666667%;
  margin-left: 4.16666667%;
  width: 91.66666667%;
}
.south .tiny {
  width: 41.66666667%;
}
.south > .slim {
  width: 50%;
}
.south > .slim .foot,
.south > .slim .part {
  margin-right: 8.33333333%;
  margin-left: 8.33333333%;
}
.south > .slim .part {
  width: 83.33333333%;
}
.south > .slim .tiny {
  width: 83.33333333%;
}
@media (max-width: 1024px) {
  .cb-layout1 div.side,
  .cb-layout3 div.side {
    margin-top: 0;
  }
  .cb-layout1 div.side div.unit,
  .cb-layout3 div.side div.unit {
    margin-top: 0;
    margin-bottom: 0;
  }
  div.farwest {
    width: 102.60416667%;
    margin-left: -1.30208333%;
  }
}
.cb-layout1 div.main div.pure.slim div.part {
  margin-left: 6.25%;
  margin-right: 6.25%;
  width: 43.359375%;
}
.cb-layout1 div.main div.pure.slim div.part.tiny {
  width: 31.640625%;
}
.cb-layout1 div.side div.seam div.part,
.cb-layout1 div.side div.flat div.part,
.cb-layout1 div.side div.edge div.part {
  margin-left: 12.5%;
  margin-right: 12.5%;
  width: 75%;
}
@media (max-width: 1024px) {
  .cb-layout3 div.main div.slim {
    width: 50%;
  }
  .cb-layout3 div.main div.slim div.part {
    margin-left: 12.5%;
    margin-right: 12.5%;
    width: 75%;
  }
}
.main > .wide.form .tile .name,
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide a.capt {
  float: left;
  width: 100%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */